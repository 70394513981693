<template>
    <div>
        <Horizontal ref="platfomCoin" v-if="$store.getters.currentPageVersion === 'Horizontal'" 
        :orderList="dataList"
        @checkDetail="checkDetail"
        @scrollList="scrollList"
        ></Horizontal>
        <Vertical ref="platfomCoin" v-else
        :orderList="dataList"
        @checkDetail="checkDetail"
        @scrollList="scrollList"
        ></Vertical>
    </div>
</template>

<script>
    import Horizontal from '@/components/Mine/PlatformCoin/Horizontal/OrderList.vue'
    import Vertical from '@/components/Mine/PlatformCoin/Vertical/OrderList.vue'
    
    export default {
        components:{
            Horizontal,
            Vertical
        },
        data() {
            return {
                currentPage:1,//当前页
                limit:10,
                dataList:[],
            }
        },
        created() {
            this.getList()
        },
        methods:{
            //获取订单列表
            getList() {
                let requestData = {
                    user_id:this.$store.state.userId,
                    token:this.$store.state.token,
                    page:this.currentPage,
                    limit:this.limit
                }
                this.$api.pay.getPlatFormCoinRechargeHistory(requestData).then(res=> {
                    if (res.data.code === 200) {
                        this.dataList = this.dataList.concat(res.data.data)
                    }
                })
            },
            //滚动列表触发
            scrollList(e) {
                    this.$common.pagingRequest(e, this)
            },
           checkDetail(e) {
               this.$router.push({
                   name: 'OrderDetail',
                   params: {
                       item:e
                   }
               })
           }
        }
    }
</script>

<style>
</style>