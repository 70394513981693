<template>
    <div class="all-page-content-box">
        <img :src="dataPack.icon" style="width: 100%;">
        <span class="tips">{{dataPack.tips}}</span>
    </div>
</template>

<script>
    import gameOrder from '@/assets/blank/blank_game_order.svg'
    import gift from '@/assets/blank/blank_gift.svg'
    import notice from '@/assets/blank/blank_notice.svg'
    import order from '@/assets/blank/blank_order.svg'
    import search from '@/assets/blank/blank_search.svg'
    import strategy from '@/assets/blank/blank_strategy.svg'

    export default {
        props: {
            type: String
        },
        data() {
            return {
                dataPack: {
                    tips:'null',
                    icon:null
                }
            }
        },
        created() {
            let tips = "",
                icon = null
            switch (this.$props.type) {
                case 'order':
                    tips = '暂无订单'
                    icon = order
                    break
                case 'gameOrder':
                    tips = '暂无游戏订单'
                    icon = gameOrder
                    break
                case 'notice':
                    tips = '暂无公告'
                    icon = notice
                    break
                case 'gift':
                    tips = '暂无礼包'
                    icon = gift
                    break
                case 'search':
                    tips = '未搜索到相关内容，请重新输入'
                    icon = search
                    break
                case 'strategy':
                    tips = '暂无攻略'
                    icon = strategy
                    break
            }
            this.dataPack.tips = tips
            this.dataPack.icon = icon
        }
    }
</script>

<style scoped>
    .all-page-content-box {
        background-color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
    .tips {
        font-size: 7.5px;
        font-weight: 400;
        color: #999999;
        padding-top: 9px;
    }
</style>
