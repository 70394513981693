<template>
    <div class="all-page">
        <TopNav title="订单记录" backPath="PlatformCoinIndex"></TopNav>
        <div class="all-page-content-box" @scroll="scrollList" ref="scrollBox" v-if="orderList.length>0">
            <div class="order-item-box" v-for="(item, index) in orderList" :key="index" @click="checkDetail(item)">
                <div class="order-info">
                    <div class="order-info-item">
                        <span class="order-info-item-title">商品</span>
                        <div class="order-info-item-value">平台币*{{(Number(item.order_money)/100).toFixed(2)}}</div>
                    </div>
                    <div class="order-info-item">
                        <span class="order-info-item-title">订单时间</span>
                        <span class="order-info-item-value">{{item.create_time | changTimeType}}</span>
                    </div>
                </div>
                <div class="order-num">
                    <img src="@/assets/common/order_money.svg">
                    <span>{{item.order_money | changMoney}}</span>
                </div>
            </div>
        </div>
        <BlankList v-else type="order"></BlankList>
    </div>
</template>

<script>
    import TopNav from "@/components/Layout/TopNav.vue"
    import BlankList from "@/components/Common/Vertical/BlankList.vue"
    export default {
        components: {
            TopNav,
            BlankList
        },
        props: {
            orderList:Array
        },
        data() {
            return {
                
            }
        },
        mounted() {
        },
        methods: {
            scrollList(e) {
                this.$nextTick(()=> {
                    this.$emit('scrollList', this.$refs.scrollBox)
                })
                
            },
            checkDetail(e) {
                this.$emit("checkDetail", e)
            }
        }
    }
</script>

<style scoped>
    .order-item-box {
        width: 100%;
        border-bottom: 4px solid #F4F4F4;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 11px 0 15px 0;
    }
    .order-info-item,
    .order-num {
        display: flex;
        align-items: center;
    }
    .order-info-item-title,
    .order-info-item-value {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
    }
    .order-info-item-title {
        
        color: #999999;
    }
    .order-info-item-value {
        padding-left: 10px;
        color: #333333;
        width:150px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .order-num>img {
        width: 7px;
    }
    .order-num>span {
        padding-left: 2px;
        font-size: 20px;
        font-weight: 400;
        color: #16C5CE;
    }
</style>
